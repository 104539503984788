import { h, render } from 'preact';
//const JamRoutes = lazy(() => import('./pages/jam/jam-routes'))
//const LandingRoutes = lazy(() => import('./routes/landing'))

import { Page } from './page'

const root = render(
  <Page />,
  document.getElementById('root') as HTMLElement,
);


export default root;