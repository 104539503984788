import { h } from 'preact';
import { useCallback, useEffect, useMemo, useRef, useState } from 'preact/hooks';


const isChrome = /Chrome/.test(navigator.userAgent) && /Google Inc/.test(navigator.vendor);

/**
 * Preloads video (or other assets)
 * Resolves with an ObjectURL containing the preloaded asset
 */
export function preloadVideo (src: string): Promise<any> {
  return new Promise((resolve, reject) => {
    const req = new XMLHttpRequest();
    req.open('GET', src, true);
    req.responseType = 'blob';

    req.onload = function() {
      if (this.status === 200) {
        var videoBlob = this.response;
        var vid = URL.createObjectURL(videoBlob);
        resolve({ oldSrc: src, src: vid });
      }
    }
    req.onerror = reject;

    req.send();
  })
}

export function Page () {

  const ref1 = useRef<HTMLVideoElement>();

  const [ past, setPast ] = useState(false);
  const [ loaded, setLoaded ] = useState('');

  const cb = useCallback(() => {
    if (document.scrollingElement && ref1.current) {
      if (document.scrollingElement.scrollTop > 120 && !past) {
        setPast(true);
      } else if (document.scrollingElement.scrollTop < 120 && !past) {
        setPast(false);
      }
      const progress =document.scrollingElement.scrollTop / document.scrollingElement.scrollHeight;
      const t = ref1.current.duration * progress;
      if (!isNaN(t)) {
        ref1.current && (ref1.current.currentTime = t);
      }
    }
  }, [ ref1, past ])

  const video = useMemo(() => {
    if (window.innerWidth > window.innerHeight) {
      return ['/assets/forest.jpg', '/assets/forest.mp4'];
    } else {
      return ['/assets/forest-mob.jpg', '/assets/forest-mob.mp4'];
    }
  }, []);

  useEffect(() => {
    if (loaded) return;
    preloadVideo(video[1]).then(({ src }) => {
      setLoaded(src);
    });
  }, [ loaded ]);

  useEffect(() => {
    if (loaded) {
      const l = setInterval(cb, 1000 / 30)
      return () => {
        clearInterval(l);
      };
    }
  }, [ loaded ])

  return (
    <div className='page' style={{height: '800vh'}}>
      <div className='video-container' style={{
        backgroundImage: `url('${video[0]}')`,
      }}>
        {loaded && <video 
          src={loaded}
          autoPlay={false}
          muted={true}
          ref={ref1 as any}
        />
        }
      </div>
  </div>
  )
}